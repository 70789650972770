import Vue from 'vue';
import * as MUTATION from '../mutationTypes';
import VisaicV3Analytics from '@/components/base-components/Analytics/VisaicV3Analytics';
import {initialState} from '@/store/auth/auth';

export default {
  [MUTATION.UPDATE_CHANNEL_KEY](state, data) {
    state.channel_key = data.key;
    Vue.$storage.set('channel_key', data.key);
  },

  [MUTATION.UPDATE_API_KEY](state, data) {
    state.api_key = data.data.api_key;
    Vue.$storage.set('api_key', data.data.api_key);
  },

  [MUTATION.UPDATE_USER_INFO](state, data) {
    const userData = data?.data;
    VisaicV3Analytics.userId = userData?.id;
    this.commit(MUTATION.UPDATE_USER_FEATURES, userData);
    if (!userData) return;
    state.user = userData;
    userData?.capabilities?.forEach((capability) => {
      state.capabilities[capability.toLowerCase()] = true;
    });
  },
  [MUTATION.UPDATE_USER_FEATURES](state, data = {}) {
    state.featureCount = {...initialState.featureCount};
    Object.entries(data?.featureCount || {})?.forEach(([feature, count]) => {
      state.featureCount[feature] = count;
    });
  },
  [MUTATION.CLEAR_USER_INFO](state) {
    state.user = null;
    VisaicV3Analytics.userId = null;
    Vue.$storage.remove('api_key');
  },
};
