import Vue from 'vue';
import store from '../store/store';
import {API, LOCAL_TIME_DISPLAYING, TIME_ZONE_FROM_SHORT} from '@/const';
import EdjsParser from 'editorjs-parser';
import {teamName} from './helpers';

/**
 * Get corrected path with custom prefix
 * @param {string} url
 * @param {string} prefix
 * @return {string}
 */
const fixPath = (url, prefix) => {
  if (url.startsWith('http')) {
    return url;
  }
  const correctUrl = url.startsWith('/') ? url : `/${url}`;
  return prefix + correctUrl;
};
/**
 * Get source path
 * @param {string} url
 * @return {string|*}
 */
const getCorrectPath = (url) => {
  if (!url) return;
  return fixPath(url, window.document.location.origin);
};

/**
 * Get image path
 * @param {string} url
 * @return {string|*}
 */
Vue.prototype.getImagePath = (url) => {
  if (!url) return;
  return fixPath(url, API.MEDIA_HOST);
};

/**
 *
 * @param {object} data
 * @param {object} config
 * @return {string}
 */
const paragraphHandler = function(data, config) {
  return `<p class="${config.paragraph.pClass} text-${data.alignment}">
    ${Vue.prototype.$t(data.text)}
  </p>`;
};

const dayFormatter = (opt) => new Intl.DateTimeFormat(Vue.i18n.locale(), opt);

Vue.prototype.getMonthAndDay = (timestamp, monthForm = 'long') => {
  timestamp = timestamp * 1000;
  const formatter = dayFormatter({
    day: 'numeric',
    month: monthForm,
  });
  return formatter.format(timestamp);
};

Vue.prototype.getHoursAndMinutes = (timestamp, withTimezone = true) => {
  timestamp = timestamp * 1000;
  const opt = {
    hour: 'numeric',
    minute: 'numeric',
  };

  if (withTimezone) {
    opt.timeZoneName = 'short';
  }

  const formatter = dayFormatter(opt);
  return formatter.format(timestamp);
};

Vue.prototype.getYear = (timestamp, yearForm = 'numeric') => {
  timestamp = timestamp * 1000;
  const formatter = dayFormatter({
    year: yearForm,
  });

  return formatter.format(timestamp);
};

/**
 * Reformat timeStamp to readable string with custom formats
 * @param {object} data
 * @param {number} data.ts - timeStamp.
 * @param {string} data.timeZone - expected time zone. ['PST' |'America/Los_Angeles']
 * @param {object} data.formatsOptions - object of day format options for each position.
 * @param {Boolean} data.asLocalTime - return time in local timezone or as admin set.
 * @return {Object}
 *
 * @example  input {
 *   ts: 1670251838,
 *   timiZone: 'America/Toronto',
 *   formatsOptions: {
 *     yr: {year: 'numeric'},
 *     month: {month: 'long'},
 *     d: {day: '2-digit'},
 *   },
 *   asLocalTime: true,
 * },
 * return {
 *   yr: '2022',
 *   month: 'December',
 *   d: '05'
 * }
 */
Vue.prototype.getFormattedDate = ({
  ts,
  timeZone,
  formatsOptions,
  asLocalTime = LOCAL_TIME_DISPLAYING,
}) => {
  const date = new Date(ts * 1000);
  const options = {};
  const locale = Vue.prototype.$i18n.locale();
  if (!asLocalTime && timeZone) {
    options.timeZone = TIME_ZONE_FROM_SHORT[timeZone] || timeZone;
  }
  const formattedDate = {};
  Object.entries(formatsOptions).forEach(([key, opt = {}]) => {
    const toLocaleStringFuncCallers = ['hh'];
    let funcName = 'toLocaleDateString';
    if (toLocaleStringFuncCallers.includes(key)) {
      funcName = 'toLocaleString';
    }
    formattedDate[key] = date[funcName]?.(locale, {...options, ...opt});
  });

  const zoneOption = {
    timeZoneName: 'short',
  };
  if (!asLocalTime && timeZone) {
    zoneOption.timeZone = TIME_ZONE_FROM_SHORT[timeZone] || timeZone;
  }
  let zone = date.toLocaleTimeString(locale, {...options, ...zoneOption});
  zone = zone.split(' ');
  zone = zone[zone.length - 1];

  formattedDate.zone = zone;
  return formattedDate;
};
Vue.prototype.trimParticipant = (name) => {
  return teamName(name);
};

const customParsers = {
  /**
   * Override paragraph for processing align
   * @param {object} data
   * @param {object} config
   * @return {string}
   */
  'paragraph': paragraphHandler,
  'static-block': function(data) {
    if (data && data.content && ~data.content.indexOf(':')) {
      const path = data.content.split(':');
      const staticItems = store.state.channel?.settings?.static_blocks;

      if (typeof staticItems?.[path[0]] !== 'undefined' &&
        typeof staticItems[path[0]][path[1]] !== 'undefined') {
        let content = '';
        if (Array.isArray(staticItems[path[0]][path[1]])) {
          const parser = new EdjsParser(undefined, {
            paragraph: paragraphHandler,
          });
          staticItems[path[0]][path[1]].map((item) => {
            if (typeof item['blocks'] !== 'undefined') {
              content += parser.parse(item);
            }
          });
        }
        return content;
      }
    }
    return '';
  },
  /**
   *
   * @param {object} data
   * @param {object} config
   * @return {string}
   */

  'image': function(data, config) {
    const imgConditions = `${data.stretched ? 'img-fullwidth' : ''} ${
      data.withBorder ? 'img-border' : ''
    } ${data.withBackground ? 'img-bg' : ''}`;
    const imgClass = config.image.imgClass || '';
    const linkTarget = data.linkTargetBlank ? '_blank' : '_self';
    const caption = data.caption;
    const linkImage = {
      open: data.wrappedLink ?
        `<a href=${getCorrectPath(data.wrappedLink)} target=${linkTarget}>` :
        '',
      close: data.wrappedLink ? '</a>' : '',
    };

    let imageSrc;
    if (data.url) {
      imageSrc = data.url;
    } else if (config.image.path === 'absolute') {
      imageSrc = data.file.url;
    } else {
      imageSrc = config.image.path.replace(
          /<(.+)>/,
          (match, p1) => data.file[p1],
      );
    }

    if (config.image.use === 'img') {
      return `${linkImage.open}
                <img class="${imgConditions} ${imgClass}" src="${imageSrc}" alt="${caption}">
              ${linkImage.close}`;
    } else if (config.image.use === 'figure') {
      const figureClass = config.image.figureClass || '';
      const figCapClass = config.image.figCapClass || '';

      return `<figure class="${figureClass}">
                ${linkImage.open}
                  <img class="${imgClass} ${imgConditions}" src="${imageSrc}" alt="${caption}">
                ${linkImage.close}
                <figcaption class="${figCapClass}">
                  ${caption}
                </figcaption>
              </figure>`;
    }
  },
};
const parser = new EdjsParser(undefined, customParsers);
/**
 *
 * @param {string} json
 * @return {string}
 */
Vue.prototype.convertEditorJsonToHtml = (json) => {
  // check for empty
  if (!json) {
    return '';
  }

  let html;

  try {
    if (typeof json === 'string') {
      json = JSON.parse(json);
    }
    html = parser.parse(json);
  } catch (e) {
    html = '';
  }

  return html;
};

/**
 * Converts a time string into a Unix timestamp.
 *
 * @param {string} timeString - The time string to convert.
 * @return {number} - The Unix timestamp equivalent of the provided time string.
 */
export function timestampFromTimeString(timeString) {
  return Math.floor(new Date(timeString).getTime() / 1000);
}
